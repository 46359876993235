body {
  background-image: url("../img/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.logo-width {
  width: 218px;
}

.connect_btn {
  background-color: #182833 !important;
  border-color: #182833 !important;
  color: white;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.w-100 {
  width: 100%;
}

.w-100-m-50 {
  width: 100%;
}

.desktop-show {
  display: block;
}

.mobile-show {
  display: none;
}

.align-items-center {
  align-items: center;
}

.footer-bg {
  background-color: #182833;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.flex-center {
  justify-content: center;
}

.step1-parent-div {
  width: 670px;
  min-height: 413px;
  background: rgba(252, 248, 233, 1);
  border-radius: 10px;
  margin: auto;
  box-shadow: inset 0 4px 19px rgba(0, 0, 0, 0.16), 0 4px 20px 0 rgba(78, 89, 97, 0.15);
  padding: 40px 50px;
}

.step2-parent-div {
  width: 670px;
  min-height: 413px;
  background: rgba(252, 248, 233, 1);
  border-radius: 10px;
  margin: auto;
  box-shadow: inset 0 4px 19px rgba(0, 0, 0, 0.16), 0 4px 20px 0 rgba(78, 89, 97, 0.15);
  padding: 0
}

.box-shadow1 {
  box-shadow: 0 0 15px 0 rgba(24, 40, 51, 0.18);
}

.radius-5 {
  border-radius: 5px;
}

.font-12 {
  font-size: 12px;
  line-height: 24px;
}

.font-14 {
  font-size: 14px;
  line-height: 24px;
}

.font-18 {
  font-size: 18px;
  line-height: 24px;
}

.bold-400 {
  font-weight: 400;
}

.bold-600 {
  font-weight: 600;
}

.bold-700 {
  font-weight: 700;
}

.color-blue-gray {
  color: rgba(78, 89, 97, 1);
}

.color-blue-light {
  color: rgba(178, 200, 208, 1);
}

.color-dark-blue {
  color: rgba(24, 40, 51, 1);
}

.color-black {
  color: black;
}

.color-light-gray {
  color: rgba(252, 248, 233, 1) !important;
}

.bg-light-gray {
  background: rgba(252, 248, 233, 1);
}

.bg-dark-blue {
  background: rgba(24, 40, 51, 1);
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.max-btn-img {
  width: 100%;
}

.input_amount {
  padding: 0 !important;
  background: #ffffff00 !important;
  border: none !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: rgba(24, 40, 51, 1) !important;
}

.input_amount:focus {
  border: none !important;
  box-shadow: none !important;
}

.input_div {
  width: 70%;
}

.balance_div {
  min-width: 55px;
}

.btn_enter_amount {
  background-color: rgba(24, 40, 51, 1) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  border-color: rgba(24, 40, 51, 0) !important;
  border-radius: 5px !important;
  padding: 20px 30px !important;
  border: 0px;
}

.positoin-relative {
  position: relative;
}

.btn-exit {
  padding: 0 !important;
  background: #ffffff00 !important;
  border: none !important;
}

.exit_div {
  position: absolute;
  right: 15px;
  top: -15px;
}

.text_underline {
  text-decoration: underline;
}

.padding-50-50 {
  padding: 50px
}

.cursur-pointer {
  cursor: pointer;
}

.btn_max {
  background: linear-gradient(0deg, #182833, #182833);
  border-color: #182833 !important;
  border-radius: 8px !important;
  padding: 7px 20px !important;
}

.btn_max:hover {
  background: linear-gradient(108.42deg, #182833 57.29%, #4E5961 100%);
  transition: all 0.5s !important;
}

.btn_enter_amount:hover {
  transition: all 0.5s !important;
  border: 0px;
  background: linear-gradient(90.67deg, #182833 36.97%, #4E5961 105.04%) !important;
}

.chain-menu {
  background: rgba(252, 248, 233, 1);
  box-shadow: inset 0 4px 19px rgba(0, 0, 0, 0.16), 0 4px 20px 0 rgba(78, 89, 97, 0.15);
  width: 240px;
  border-radius: 8px;
  padding: 20px 15px;
  position: absolute;
  right: 0px;
  top: 50px;
  z-index: 99;
}

.chain-menu-item {
  border-radius: 8px;
}

.chain-menu-item:hover {
  background: rgba(178, 200, 208, 1);
}

.desktop-show1 {
  display: block;
}

.mobile-show1 {
  display: none;
}

.step2-spin-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-dashline {
  width: 65px;
  border-bottom: 2px dashed #182833;
}

@media (max-width:980px) {
  .logo-width {
    width: 115px;
  }

  .w-100-m-50 {
    width: 70% !important;
  }

  .desktop-show {
    display: none !important;
  }

  .mobile-show {
    display: block;
  }

  .step1-parent-div {
    width: 100%;
    padding: 30px 20px;
    margin-bottom: 100px;
  }

  .step2-parent-div {
    width: 100%;
    margin-bottom: 100px;
  }

  .max-btn-img {
    width: 65px;
  }

  .padding-50-50 {
    padding: 0px
  }
}

@media (max-width:572px) {
  .desktop-show1 {
    display: none !important;
  }

  .mobile-show1 {
    display: block;
  }

  .step2-spin-div {
    display: block;
    text-align: center;
  }

  .h-dashline {
    width: 65px;
    border-bottom: 2px dashed #182833;
    transform: rotate(90deg);
    margin-bottom: 45px;
    margin-top: 25px;
  }

  .h-dashline1 {
    margin-top: 45px !important;
  }
}

@media (max-width:780px) {
  .w-100-m-50 {
    width: 62% !important;
  }
}

@media (max-width:532px) {
  .w-100-m-50 {
    width: 65% !important;
  }
}

@media (max-width:487px) {
  .w-100-m-50 {
    width: 64% !important;
  }
}

@media (max-width:476px) {
  .w-100-m-50 {
    width: 59% !important;
  }
}

@media (max-width:440px) {
  .w-100-m-50 {
    width: 52% !important;
  }
}

@media (max-width:406px) {
  .w-100-m-50 {
    width: 46% !important;
  }
}

@media (max-width:393px) {
  .w-100-m-50 {
    width: 45% !important;
  }
}

@media (max-width:368px) {
  .w-100-m-50 {
    width: 44% !important;
  }
}

@media (max-width:334px) {
  .w-100-m-50 {
    width: 35% !important;
  }
}
